// Rotes Config File to Maintain all Routes and reuse them
const ROUTES = {
    login: '/login',
    loadSession: '/loadSession',
    survey: '/surveyList',
    takeSurvey: '/takeSurvey',
    pledgeList: '/pledge-list',
    takePledge: '/takePledge',
    footprintValue: '/showFootprintScore',
    eachResult: '/result',
    newPledge: '/newPledge',
    pageNotFound: '/pageNotFound',
    pledgeScreen: '/pledge',
    pledgeLog: '/pledge-log',
    // privacy: '/privacy-policy',
    // termsAndCondtions: '/terms-and-conditions',
    banner: '/banner',
    // ------------------------ EXTENSION --------------------------
    extension: '/extension',
    extensionList: '/extensionSurveyList',
    takeCarbonSurvey: '/takeCarbonSurvey',
    loginCarbonSurvey: '/loginCarbonSurvey',
    verifyCarbonSurvey: '/verifyCarbonSurvey',
    extensionResult: '/extensionResult',
    extensionPledges: '/extensionPledges',
    ExtensionPledgeLog: '/pledge-log-extension',
    extensionPledgeScreen: '/pledges',

    // ------------------------- landing Page---------------------------
    PRIVACY: '/privacy-policy',
    PRIVACY_PDF: '/privacy',
    TERMS: '/terms&conditions',
    TERMS_PDF: '/terms',
    EVENTS_SCREEN: '/events',

    //-----------------------------Volunteer Page--------------------------
    VOLUNTEER_FORM: '/volunteer',
    VOLUNTEER_RESULT: '/volunteer/submission'
}
const API_ROUTES = {
    FETCH_SURVEY_QUESTIONS: 'c4lapiSurveyApp/survey/fetchSurveyQuestions',
    SUBMIT_SURVEY_QUESTIONS: 'c4lapiSurveyApp/survey/submitSurveyQuestions',
    FETCH_EXTENSION_SURVEY_QUESTIONS: 'c4lapiExtensionApp/extension/fetchCarbonSurveyQuestions',
    SUBMIT_EXTENSION_SURVEY_QUESTIONS: 'c4lapiExtensionApp/extension/submitCarbonFootprintSurvey',
    SUBMIT_VERIFIED_EXTENSION_SURVEY_QUESTIONS: 'c4lapiExtensionApp/extension/verifyC4LExtensionUser',
    FETCH_PLEDGES: 'c4lapiPledgeApp/pledge/pledgesList',
    UPDATE_PLEDGE: 'c4lapiPledgeApp/pledge/updatePledge',
    BEGIN_END_PLEDGE: 'c4lapiPledgeApp/pledge/beginOrEndThePledge',
    GET_EVENTS: 'webRoutes/event',
    POST_VOLUNTEER: 'webRoutes/volunteer'
}
export { ROUTES, API_ROUTES }
