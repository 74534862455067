import React from 'react'
import classes from './volunteer.module.css'
import { Typography } from '@mui/material'
import Googleplay from '../../assets/homepage_assets/Googleplay.svg'
import Appstore from '../../assets/homepage_assets/Appstore.svg'
import logo from '../../assets/homepage_assets/C4L Logo.svg'

const VolunteerResult = () => {
    return (
        <div className={classes.volunteer_container}>
            <div className={classes.submissionContainer}>
                <div className={classes.submissionHeaderCard}>
                    <Typography variant="h4" color="#fff" marginBottom="20px">
                        Thank you for registering
                    </Typography>
                </div>
                <div className={classes.submissionTextCard}>
                    <div className={classes.submissionTextContent}>
                        <Typography align="center" variant="h6">
                            Your registration has been successfully saved.{' '}
                        </Typography>
                        <Typography align="center" variant="p1">
                            Thanks for your commitment to make a change!
                        </Typography>
                        <Typography align="center" variant="p1">
                            You are now part of the TCS Volunteering effort to inspire others to make an impact through
                            actions. All volunteering actions - big or small help contribute towards a better,
                            sustainable and purposeful community.
                        </Typography>
                        <Typography align="center" variant="p1">
                            We have a new version of the Circle4Life app that has more sustainability related events and
                            content.
                        </Typography>
                        <Typography align="center" variant="p1">
                            Available only for TCS employees
                        </Typography>
                        <Typography align="center" variant="h6">
                            DOWNLOAD NOW
                        </Typography>
                        <Typography align="center" variant="b1">
                            BUILT BY TCSERS, SPECIALLY FOR TCSERS
                        </Typography>
                    </div>
                </div>
                <div className={classes.submissionFooterCard}>
                    <img src={logo} alt="Circle for Life" />
                    <a
                        href="https://play.google.com/store/apps/details?id=com.tcs.circle4life"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={Googleplay} alt="Google playstore link" />
                    </a>
                    <a
                        href="https://apps.apple.com/in/app/tcs-circle4life/id1593154523"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={Appstore} alt="Appstore link" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default VolunteerResult
